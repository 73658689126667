var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mb-4",
          attrs: { height: "auto", width: "auto", flat: "" },
        },
        [
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              [
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "text-h4 font-weight-bold" }, [
                    _vm._v(" Clean the company First Draft "),
                  ]),
                ]),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 1. Get List of all companies exclude temporary "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchAllCompaniesExcludeTemporary },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", [
                  _c(
                    "div",
                    [
                      _vm.loadingCompaniesWithoutTemporary
                        ? _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          })
                        : _vm._e(),
                      this.companiesWithoutTemporary != null
                        ? _c("v-data-table", {
                            staticClass: "elevation-3 mt-4",
                            attrs: {
                              search: _vm.searchCompaniesWithoutTemporary,
                              dense: "",
                              outlined: "",
                              headers: _vm.companiesNotFoundHeader,
                              items: this.companiesWithoutTemporary,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        {
                                          staticClass: "ma-5",
                                          attrs: { flat: "", height: "auto" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                "no-gutters": "",
                                                color: "primary class=mt-4",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "mt-3 mx-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold text-h4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " All companies without temporary "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    staticClass: "mt-4",
                                                    attrs: {
                                                      label: "Search company",
                                                      clearable: "",
                                                      filled: "",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.searchCompaniesWithoutTemporary,
                                                      callback: function ($$v) {
                                                        _vm.searchCompaniesWithoutTemporary =
                                                          $$v
                                                      },
                                                      expression:
                                                        "searchCompaniesWithoutTemporary",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click:
                                                  _vm.updateCompanyToUnknownAllCompaniesWithoutTemporary,
                                              },
                                            },
                                            [_vm._v(" Update To Unknown ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2412661889
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(
                      " 2. Get List of Companies From Companies not found with the sales list "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesNotFoundInExcel },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  [
                    _vm.loadingCompanyNotFoundExcel
                      ? _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        })
                      : _vm._e(),
                    this.companiesNotFoundInExcel != null
                      ? _c("v-data-table", {
                          staticClass: "elevation-3 mt-4",
                          attrs: {
                            search: _vm.searchCompanyNotFoundExcel,
                            dense: "",
                            outlined: "",
                            headers: _vm.companiesNotFoundHeader,
                            items: this.companiesNotFoundInExcel,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "top",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-toolbar",
                                      {
                                        staticClass: "ma-5",
                                        attrs: { flat: "", height: "auto" },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              "no-gutters": "",
                                              color: "primary class=mt-4",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-3 mx-3" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold text-h4",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Unmatched Company "
                                                    ),
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  staticClass: "mt-4",
                                                  attrs: {
                                                    label: "Search company",
                                                    clearable: "",
                                                    filled: "",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.searchCompanyNotFoundExcel,
                                                    callback: function ($$v) {
                                                      _vm.searchCompanyNotFoundExcel =
                                                        $$v
                                                    },
                                                    expression:
                                                      "searchCompanyNotFoundExcel",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "primary" },
                                            on: {
                                              click:
                                                _vm.updateCompanyUnmatchedToInvalid,
                                            },
                                          },
                                          [_vm._v(" Update To Invalid ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2789710179
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(
                      " 3. Get List of Companies From Companies Intersect With sales vision Key "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesIntersectVisionKey },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCompanyIntersectVisionKey
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                _vm.companiesIntersectVisionKey != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      attrs: {
                        search: _vm.searchCompanyIntersectVisionKey,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesHeader,
                        items: _vm.companiesIntersectVisionKey,
                        "footer-props": {
                          showFirstLastPage: true,
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" Matched Company ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search company",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCompanyIntersectVisionKey,
                                                callback: function ($$v) {
                                                  _vm.searchCompanyIntersectVisionKey =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCompanyIntersectVisionKey",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click:
                                            _vm.updateCompanyToValidIntersectSalesList,
                                        },
                                      },
                                      [_vm._v(" Update To Valid ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "item.index",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.companiesIntersectVisionKey.indexOf(
                                        item
                                      ) + 1
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        69543135
                      ),
                    })
                  : _vm._e(),
                _vm.companiesProblem != null
                  ? _c("v-simple-table", {
                      staticClass: "elevation-3 mt-5",
                      attrs: {
                        "max-": "",
                        height: "500px",
                        dense: "",
                        outlined: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [_c("th", [_vm._v(" Name ")])]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.companiesProblem,
                                    function (company) {
                                      return _c("tr", { key: company }, [
                                        _c("td", [
                                          _vm._v(" " + _vm._s(company) + " "),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3234980940
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "font-weight-bold text-h4" }, [
                    _vm._v(" Clean Testing Company "),
                  ]),
                ]),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" Secret key "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label:
                            "Please insert secret key if you want to proceed",
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.secretKey,
                          callback: function ($$v) {
                            _vm.secretKey = $$v
                          },
                          expression: "secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 1. Enter the company id "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Company id", dense: "", outlined: "" },
                        model: {
                          value: _vm.cleanCompanyId,
                          callback: function ($$v) {
                            _vm.cleanCompanyId = $$v
                          },
                          expression: "cleanCompanyId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 2. Find the renewal status "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesTestingRenewalStatus },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCleanCompaniesRenewal
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                this.cleanCompanyRenewal != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      attrs: {
                        search: _vm.searchCleanCompaniesRenewal,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesRenewalHeader,
                        items: this.cleanCompanyRenewal,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" All renewal status ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCleanCompaniesRenewal,
                                                callback: function ($$v) {
                                                  _vm.searchCleanCompaniesRenewal =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCleanCompaniesRenewal",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: _vm.ClearCompaniesRenewal,
                                        },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        566764769
                      ),
                    })
                  : _vm._e(),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 3. Find the payment status "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesTestingPaymentStatus },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCleanCompaniesPayment
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                this.cleanCompanyPayment != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      attrs: {
                        search: _vm.searchCleanCompaniesPayment,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesPaymentHeader,
                        items: this.cleanCompanyPayment,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" All payment status ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCleanCompaniesPayment,
                                                callback: function ($$v) {
                                                  _vm.searchCleanCompaniesPayment =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCleanCompaniesPayment",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: _vm.ClearCompaniesPayment,
                                        },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1063481437
                      ),
                    })
                  : _vm._e(),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 4. Find the proof of payment "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesTestingReceipt },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCleanCompaniesReceipt
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                this.cleanCompanyReceipt != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      staticStyle: { "max-width": "500px" },
                      attrs: {
                        search: _vm.searchCleanCompaniesReceipt,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesReceiptHeader,
                        items: this.cleanCompanyReceipt,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" All receipt ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCleanCompaniesReceipt,
                                                callback: function ($$v) {
                                                  _vm.searchCleanCompaniesReceipt =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCleanCompaniesReceipt",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: _vm.ClearCompaniesReceipt,
                                        },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1743695599
                      ),
                    })
                  : _vm._e(),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 5. Find the invoice "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesTestingInvoice },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCleanCompaniesInvoice
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                this.cleanCompanyInvoice != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      staticStyle: { "max-width": "500px" },
                      attrs: {
                        search: _vm.searchCleanCompaniesInvoice,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesReceiptHeader,
                        items: this.cleanCompanyInvoice,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" All Invoices ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCleanCompaniesInvoice,
                                                callback: function ($$v) {
                                                  _vm.searchCleanCompaniesInvoice =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCleanCompaniesInvoice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: _vm.ClearCompaniesInvoice,
                                        },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3301798289
                      ),
                    })
                  : _vm._e(),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 6. Find the pic "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesTestingPic },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCleanCompaniesPic
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                this.cleanCompanyPic != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      staticStyle: { "max-width": "500px" },
                      attrs: {
                        search: _vm.searchCleanCompaniesPic,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesPicHeader,
                        items: this.cleanCompanyPic,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" All PIC ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCleanCompaniesPic,
                                                callback: function ($$v) {
                                                  _vm.searchCleanCompaniesPic =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCleanCompaniesPic",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: { click: _vm.ClearCompaniesPic },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3089197225
                      ),
                    })
                  : _vm._e(),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 7. Find the notification "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesTestingNotification },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCleanCompaniesNotification
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                this.cleanCompanyNotification != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      staticStyle: { "max-width": "500px" },
                      attrs: {
                        search: _vm.searchCleanCompaniesNotification,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesNotificationHeader,
                        items: this.cleanCompanyNotification,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" All Notification ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCleanCompaniesPic,
                                                callback: function ($$v) {
                                                  _vm.searchCleanCompaniesPic =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCleanCompaniesPic",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: _vm.ClearCompaniesNotification,
                                        },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1407103177
                      ),
                    })
                  : _vm._e(),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 8. Find the task "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesTestingTask },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCleanCompaniesTask
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                this.cleanCompanyTask != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      staticStyle: { "max-width": "500px" },
                      attrs: {
                        search: _vm.searchCleanCompaniesTask,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesTaskHeader,
                        items: this.cleanCompanyTask,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" All Task ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCleanCompaniesPic,
                                                callback: function ($$v) {
                                                  _vm.searchCleanCompaniesPic =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCleanCompaniesPic",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: { click: _vm.ClearCompaniesTask },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3507770633
                      ),
                    })
                  : _vm._e(),
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c("div", { staticClass: "d-flex mx-4" }, [
                    _vm._v(" 9. Find the Log "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex " },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.fetchCompaniesTestingLog },
                        },
                        [_vm._v(" Go ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.loadingCleanCompaniesLog
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    })
                  : _vm._e(),
                this.cleanCompanyLog != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-3 mt-4",
                      staticStyle: { "max-width": "500px" },
                      attrs: {
                        search: _vm.searchCleanCompaniesLog,
                        dense: "",
                        outlined: "",
                        headers: _vm.companiesLogHeader,
                        items: this.cleanCompanyLog,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "ma-5",
                                    attrs: { flat: "", height: "auto" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          "no-gutters": "",
                                          color: "primary class=mt-4",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-h4",
                                              },
                                              [_vm._v(" All Log ")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                label: "Search",
                                                clearable: "",
                                                filled: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchCleanCompaniesLog,
                                                callback: function ($$v) {
                                                  _vm.searchCleanCompaniesLog =
                                                    $$v
                                                },
                                                expression:
                                                  "searchCleanCompaniesLog",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: { click: _vm.ClearCompaniesLog },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2589392375
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", [
              _c("div", { staticClass: "d-flex flex-row my-4" }, [
                _c("div", { staticClass: "text-h4 font-weight-bold" }, [
                  _vm._v(" Report JSON "),
                ]),
              ]),
              _c("div", { staticClass: "d-flex flex-row my-4" }, [
                _c("div", { staticClass: "d-flex mx-4" }, [
                  _vm._v(" Get List of Companies From Sales List "),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex " },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.fetchCompaniesFromExcel },
                      },
                      [_vm._v(" Go ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "d-flex flex-row justify-start my-4" }, [
                _c("div", { staticClass: "mx-4" }, [
                  _vm._v(" Get list of companies in companies table "),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.fetchTotalCompanies },
                      },
                      [_vm._v(" Go ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "d-flex flex-row justify-start my-4" }, [
                _c("div", { staticClass: "mx-4" }, [
                  _vm._v(
                    " Get list of companies from table companies intersect with excel "
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.fetchCompaniesFromExcel },
                      },
                      [_vm._v(" Go ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.totalCompaniesByVerification != null
        ? _c("v-card", { staticClass: "pa-4 my-5" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function () {
                        _vm.totalCompaniesByVerification = null
                      },
                    },
                  },
                  [_vm._v(" Close ")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                [
                  _c("div", { staticClass: "d-flex justify-center" }, [
                    _c("div", [
                      _vm._v(" Total of companies in companies table : "),
                    ]),
                  ]),
                  _vm.loadingCompanyVerification
                    ? _c("v-skeleton-loader", {
                        ref: "skeleton",
                        attrs: { type: "table" },
                      })
                    : _vm._e(),
                  this.totalCompaniesByVerification
                    ? _c("div", [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "font-weight bold text-h7" },
                                  [_vm._v(" Valid ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("div", [
                                  _vm._v(
                                    " Total Companies " +
                                      _vm._s(
                                        this.totalCompaniesByVerification.valid
                                          .total
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("v-textarea", {
                              attrs: {
                                filled: "",
                                solo: "",
                                width: "auto",
                                height: "auto",
                              },
                              model: {
                                value:
                                  this.totalCompaniesByVerification.valid
                                    .companies,
                                callback: function ($$v) {
                                  _vm.$set(
                                    this.totalCompaniesByVerification.valid,
                                    "companies",
                                    $$v
                                  )
                                },
                                expression:
                                  "this.totalCompaniesByVerification.valid.companies",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "font-weight bold text-h7" },
                                  [_vm._v(" Invalid ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("div", [
                                  _vm._v(
                                    " Total Companies " +
                                      _vm._s(
                                        this.totalCompaniesByVerification
                                          .invalid.total
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("v-textarea", {
                              attrs: {
                                filled: "",
                                solo: "",
                                width: "auto",
                                height: "auto",
                              },
                              model: {
                                value:
                                  this.totalCompaniesByVerification.invalid
                                    .companies,
                                callback: function ($$v) {
                                  _vm.$set(
                                    this.totalCompaniesByVerification.invalid,
                                    "companies",
                                    $$v
                                  )
                                },
                                expression:
                                  "this.totalCompaniesByVerification.invalid.companies",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "font-weight bold text-h7" },
                                  [_vm._v(" Unknown ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("div", [
                                  _vm._v(
                                    " Total Companies " +
                                      _vm._s(
                                        this.totalCompaniesByVerification
                                          .unknown.total
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("v-textarea", {
                              attrs: {
                                filled: "",
                                solo: "",
                                width: "auto",
                                height: "auto",
                              },
                              model: {
                                value:
                                  this.totalCompaniesByVerification.unknown
                                    .companies,
                                callback: function ($$v) {
                                  _vm.$set(
                                    this.totalCompaniesByVerification.unknown,
                                    "companies",
                                    $$v
                                  )
                                },
                                expression:
                                  "this.totalCompaniesByVerification.unknown.companies",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "font-weight bold text-h7" },
                                  [_vm._v(" Internal ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("div", [
                                  _vm._v(
                                    " Total Companies " +
                                      _vm._s(
                                        this.totalCompaniesByVerification
                                          .internal.total
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("v-textarea", {
                              attrs: {
                                filled: "",
                                solo: "",
                                width: "auto",
                                height: "auto",
                              },
                              model: {
                                value:
                                  this.totalCompaniesByVerification.internal
                                    .companies,
                                callback: function ($$v) {
                                  _vm.$set(
                                    this.totalCompaniesByVerification.internal,
                                    "companies",
                                    $$v
                                  )
                                },
                                expression:
                                  "this.totalCompaniesByVerification.internal.companies",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.loadingCompanyExcel
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      this.totalCompaniesFromExcel != null
        ? _c("v-card", { staticClass: "pa-4 mt-5" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function () {
                        _vm.totalCompaniesFromExcel = null
                      },
                    },
                  },
                  [_vm._v(" Close ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticClass: "d-flex justify-center" }, [
                  _c("div", {}, [_vm._v(" Companies from excel ")]),
                ]),
                _c("div", { staticClass: "d-flex justify-center" }, [
                  _c("div", {}, [
                    _vm._v(
                      " Total companies : " +
                        _vm._s(this.totalCompaniesFromExcel.total) +
                        " "
                    ),
                  ]),
                ]),
                _c("v-textarea", {
                  attrs: { filled: "", solo: "" },
                  model: {
                    value: this.totalCompaniesFromExcel.companies,
                    callback: function ($$v) {
                      _vm.$set(this.totalCompaniesFromExcel, "companies", $$v)
                    },
                    expression: "this.totalCompaniesFromExcel.companies",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }