var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingDeleteTemporary },
        on: {
          cancel: _vm.cancelSubmit,
          confirm: _vm.deleteCompanyTemporaryInvalid,
        },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingDeleteInvalid },
        on: {
          cancel: _vm.cancelSubmitDeleteInvalid,
          confirm: _vm.deleteCompanyInvalid,
        },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingUpdateTemporaryStatus },
        on: {
          cancel: _vm.cancelCompanyTemporaryStatus,
          confirm: _vm.updateCompanyTemporaryStatus,
        },
      }),
      _vm.api.isSuccesful
        ? _c(
            "v-alert",
            { attrs: { type: "success", border: "left", dense: "" } },
            [_vm._v(" " + _vm._s(this.api.success) + " ")]
          )
        : _vm._e(),
      _c(
        "v-toolbar",
        {
          staticClass: "pa-5",
          attrs: { flat: "", height: "auto", width: "auto" },
        },
        [
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("div", { staticClass: "d-flex flex-row my-4" }, [
              _c("div", { staticClass: "d-flex mx-4" }, [
                _vm._v(" Update All Companies With TEMPORARY name to Invalid "),
              ]),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.validateCompanyTemporaryStatus },
                    },
                    [_vm._v(" Go ")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "d-flex flex-row my-4" }, [
              _c("div", { staticClass: "d-flex mx-4" }, [
                _vm._v(
                  " Delete All companies With TEMPORARY name and Invalid status "
                ),
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", outlined: "" },
                      on: { click: _vm.validateConfirmation },
                    },
                    [_vm._v(" Delete ")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "d-flex flex-row my-4" }, [
              _c("div", { staticClass: "d-flex mx-4" }, [
                _vm._v(" Delete all Invalid companies "),
              ]),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", outlined: "" },
                      on: { click: _vm.validateConfirmationDeleteInvalid },
                    },
                    [_vm._v(" Delete ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("v-card", [_c("div")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }